import { imagesHome } from '~/assets/images';

export const dataSupplier = [
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
  {
    image: imagesHome.supplier_img,
    title: 'Chiko Hàng Nhật: TÌM SỈ HOẶC ĐẠI LÝ VỀ HÀNG NỘI ĐỊA NHẬT',
    description: 'Tìm nhà cung cấp',
    location: 'Toàn quốc',
  },
];
